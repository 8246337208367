// import React from 'react';
// import './PaginaInicio.css';

// function Inicio() {
//   return (
//     <div className="App">
//       <h1 className="fleet-area-title">Área de Flota</h1>
//       <div className="center-box">
//         <h2 className="executive-title">Indicadores</h2>
//         <a href="https://app.powerbi.com/view?r=eyJrIjoiYzQyNDA2MTctMmRjMi00ODM3LWE5NmItYmVmYTY5MWVlYmI4IiwidCI6IjMyNDhmMzBmLTVkMmQtNDMwZi04ZWM0LTljMTI3NjUyZmIwZiIsImMiOjR9" target="_blank" rel="noopener noreferrer" className="button-link">
//           <button className="executive-button">Disponibilidad de Unidades</button>
//         </a>
//         <a href="https://app.powerbi.com/view?r=eyJrIjoiZDk5ZjdmM2EtNjgyMi00YTgzLThlYzMtM2VkMWVjZTgyNjI3IiwidCI6IjMyNDhmMzBmLTVkMmQtNDMwZi04ZWM0LTljMTI3NjUyZmIwZiIsImMiOjR9" target="_blank" rel="noopener noreferrer" className="button-link">
//           <button className="executive-button">Ralentí</button>
//         </a>
//       </div>
//     </div>
//   );
// }

// export default Inicio;


import React from 'react';
import './PaginaInicio.css';

function Inicio() {
  return (
    <div className="App">
      <header className="App-header">
        <h1 className="fleet-area-title">Área de Flota</h1>
      </header>
      <main className="App-main">
        <div className="center-box">
          <a href="https://docs.google.com/forms/d/e/1FAIpQLSeDWv2bUAKpbUnkVyC4pLCLNlvXvbs1SwWuVDKgtmSIogB5KQ/viewform?usp=sf_link" target="_blank" rel="noopener noreferrer" className="button-link red-button">
            <button className="executive-button red-button">Formulario registro de incidentes</button>
          </a>
          <h2 className="executive-title">Indicadores</h2>
          <a href="https://app.powerbi.com/view?r=eyJrIjoiYzQyNDA2MTctMmRjMi00ODM3LWE5NmItYmVmYTY5MWVlYmI4IiwidCI6IjMyNDhmMzBmLTVkMmQtNDMwZi04ZWM0LTljMTI3NjUyZmIwZiIsImMiOjR9" target="_blank" rel="noopener noreferrer" className="button-link">
            <button className="executive-button">Disponibilidad de Unidades</button>
          </a>
          <a href="https://app.powerbi.com/view?r=eyJrIjoiZDk5ZjdmM2EtNjgyMi00YTgzLThlYzMtM2VkMWVjZTgyNjI3IiwidCI6IjMyNDhmMzBmLTVkMmQtNDMwZi04ZWM0LTljMTI3NjUyZmIwZiIsImMiOjR9" target="_blank" rel="noopener noreferrer" className="button-link">
            <button className="executive-button">Ralentí</button>
          </a>
          <a href="https://app.powerbi.com/view?r=eyJrIjoiYjBhYTgxMWQtN2FkMS00OGZjLWFkZTUtY2MzYmYwNzJjMzRjIiwidCI6IjMyNDhmMzBmLTVkMmQtNDMwZi04ZWM0LTljMTI3NjUyZmIwZiIsImMiOjR9" target="_blank" rel="noopener noreferrer" className="button-link">
            <button className="executive-button">Carretas y Carretones</button>
          </a>
          <a href="https://app.powerbi.com/view?r=eyJrIjoiNDlhNzI2MTAtMTE0NS00NDZmLTk4YjItOTQxOWU0YjA5MmUyIiwidCI6IjMyNDhmMzBmLTVkMmQtNDMwZi04ZWM0LTljMTI3NjUyZmIwZiIsImMiOjR9" target="_blank" rel="noopener noreferrer" className="button-link">
            <button className="executive-button">Incidentes en Megas</button>
          </a>
          
        </div>
      </main>
      <footer className="App-footer">
        <p>&copy; {new Date().getFullYear()} Distribuidora PACI & SELPA</p>
      </footer>
    </div>
  );
}

export default Inicio;

